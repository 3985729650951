import './App.css';
import {LoginWithTikTok} from "./Components/Pages/Login/LoginWithTikTok";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {LoginWithTikTokCallback} from "./Components/Pages/Login/LoginWithTiktokCallback";
import {MantineProvider, Notification} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {useEffect, useState} from "react";
import {AppContext} from "./Contexts/App";
import {Dashboard} from "./Components/Pages/Dashboard/Dashboard";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AdminNavbar, NavbarSimple} from "./Components/Layout/AdminNavbar";
import {createTheme, NextUIProvider} from "@nextui-org/react";
import {Products} from "./Components/Pages/Products/Products";
import {ExpensesList} from "./Components/Pages/Expenses/ExpensesList";
import {ViewProduct} from "./Components/Pages/Products/ViewProduct";
import {Settings} from "./Components/Pages/Settings/Settings";
import {AllNotifications} from "./Components/Pages/AllNotifications";
import {ConfigureProduct} from "./Components/Pages/Products/ConfigureProduct";
import {Expenses} from "./Components/Pages/Expenses/Expenses";
import {Login} from "./Components/Pages/Login/Login";
import {SetupLoginDetails} from "./Components/Pages/Onboarding/SetupLoginDetails/SetupLoginDetails";
import {Import} from "./Components/Pages/Onboarding/OnboardCustomer/Import";
import {UserLoader} from "./Components/Layout/UserLoader";
import {PlatformFeeConfiguration} from "./Components/Pages/Onboarding/OnboardCustomer/PlatformFeeConfiguration";
import {OnboardingWrapper} from "./Components/Pages/Onboarding/OnboardCustomer/OnboardingWrapper";
import moment from "moment";
import {Billing} from "./Components/Pages/Billing/Billing";
import {Help} from "./Components/Pages/Help/Help";
import {ShopAccounts} from "./Components/Pages/ShopAccounts/ShopAccounts";
import {Reports} from "./Components/Pages/Reports/Reports";
import {CreateEditReport} from "./Components/Pages/Reports/CreateEditReport";
import {ReimbursementReportList} from "./Components/Pages/MoneyBack/ReimbursementReportList";
import {ReimbursementReports} from "./Components/Pages/MoneyBack/ReimbursementReports";
import { Logo } from './Components/Layout/Logo';

const queryClient = new QueryClient()

function App() {
    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)
    const [shopAccountIds, setShopAccountIds] = useState(null)
    const [settlementsOnly, setSettlementsOnly] = useState(false)
    const [includeShippingAsCost, setIncludeShippingAsCost] = useState(true)
    const [shippingPercentage, setShippingPercentage] = useState(0)
    const [calendarPeriod, setCalendarPeriodValue] = useState("last_30_days_by_day")
    const [settingsTab, setSettingsTab] = useState("tiktok_platform_fees")
    const [dashboardTab, setDashboardTab] = useState("charts")
    const [isLoginEnabled, setIsLoginEnabled] = useState(false)
    const [isOnboardingMode, setIsOnboardingMode] = useState(false)
    const [onboardingStatus, setOnboardingStatus] = useState(null)
    const [onboardingState, setOnboardingState] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [subscriptionPaymentFailed, setSubscriptionPaymentFailed] = useState(false)
    const [subscriptionInactive, setSubscriptionInactive] = useState(false)

    const theme = createTheme({
        type: 'light',
    });

    const setCalendarPeriod = (value) => {
        localStorage.setItem("SHOPA-calendar-period", value)
        setCalendarPeriodValue(value)
    }

    const clearAppContext = () => {
        localStorage.setItem("SHOPA-user", null)
        localStorage.setItem("SHOPA-shopAccountIds", null)
        localStorage.setItem("SHOPA-token", null)
        localStorage.setItem("SHOPA-session-expired", null)
        localStorage.setItem("SHOPA-settled-mode", null)
        localStorage.setItem("SHOPA-calendar-period", null)
        setUser(null)
        setShopAccountIds(null)
        setToken(null)
        setSettlementsOnly(null)
        setCalendarPeriodValue(null)
    }

    useEffect(() => {
        // load user from local storage
        const localStorageUser = localStorage.getItem("SHOPA-user");
        if (localStorageUser && localStorageUser != "null") {
            setUser(JSON.parse(localStorageUser))
        }

        // load user from local storage
        const localStorageToken = localStorage.getItem("SHOPA-token");
        if (localStorageToken && localStorageToken != "null") {
            setToken(localStorageUser)
        }

        // load calendar period
        const localCalendarPeriod = localStorage.getItem("SHOPA-calendar-period");
        if (localCalendarPeriod && localCalendarPeriod != "null") {
            setCalendarPeriodValue(localCalendarPeriod)
        }

        // load shopAccountIds from local storage
        const shopAccountIds = localStorage.getItem("SHOPA-shopAccountIds");
        if (
            shopAccountIds &&
            shopAccountIds !== "null"
        ) {
            const shopIdsAsStrArr = shopAccountIds.split(",")
            setShopAccountIds(shopIdsAsStrArr)
        }
    }, []);

    const context = {
        user,
        setUser,
        token,
        setToken,
        shopAccountIds,
        setShopAccountIds,
        settlementsOnly,
        setSettlementsOnly,
        includeShippingAsCost,
        setIncludeShippingAsCost,
        shippingPercentage,
        setShippingPercentage,
        calendarPeriod,
        setCalendarPeriod,
        settingsTab,
        setSettingsTab,
        setDashboardTab,
        dashboardTab,
        clearAppContext,
        setIsLoginEnabled,
        setIsOnboardingMode,
        setOnboardingState,
        setOnboardingStatus,
        subscription,
        setSubscription,
        subscriptionPaymentFailed,
        setSubscriptionPaymentFailed,
        setSubscriptionInactive,
        subscriptionInactive
    }

  return (
      <BrowserRouter>
          <NextUIProvider theme={theme}>
              <MantineProvider
                  withGlobalStyles
                  withNormalizeCSS
                  theme={{
                      colorScheme: 'light'
                  }}
              >
                  <QueryClientProvider client={queryClient}>
                      <AppContext.Provider value={context}>
                          <Notifications />
                          <div
                          style={{
                            backgroundColor: "#f0f0f0",
                            padding: "20px", 
                            backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('https://mergoio.com/assets/images/dashboard2.png')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundAttachment: "fixed",
                            minHeight: "100vh"
                          }}
                          >
                          <div className="h-full py-20 mx-auto max-w-4xl flex flex-col items-center justify-center px-4 text-center">
                              <img 
                                src="https://mergoio.com/assets/images/mergoiologo.png"
                                alt="Mergoio Logo"
                                className="w-64 mb-12"
                              />
                              
                              <h1 className="text-3xl font-bold mb-8 text-white">ShopFlow is now Mergoio</h1>
                              
                              <p className="text-xl mb-12 text-white">
                                We've joined forces with Mergoio to provide you with an even better TikTok Shop management platform. Continue your journey with us by creating a new account on Mergoio.
                              </p>

                              <a 
                                  href="https://mergoio.com" 
                                  className="bg-blue-600 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:bg-blue-700 transition-colors"
                              >
                                  Sign up on Mergoio
                              </a>

                              <p className="text-sm mt-8 text-gray-400">
                                Note for existing customers: We have cancelled your current ShopFlow subscription and refunded your last billing cycle. To continue using our services, please create a new account on Mergoio.
                              </p>
                          </div>
                          </div>
                          {/* {!token && (
                              <Routes>
                                  <Route path="*" element={(<Login/>)}/>
                                  <Route path="/" element={(<Login/>)}/>
                                  <Route path="/oauth/callback" element={(<LoginWithTikTokCallback/>)}/>
                              </Routes>
                          )}
                          {token && (
                              <>
                                  <UserLoader />
                                  {!isLoginEnabled && (
                                      <SetupLoginDetails />
                                  )}
                                  {isLoginEnabled && (
                                      <>
                                          {isOnboardingMode && (
                                              <OnboardingWrapper />
                                          )}
                                          {!isOnboardingMode && (
                                              <>
                                                  {(subscriptionPaymentFailed || subscriptionInactive) && (
                                                      <div className={"flex h-full"}>
                                                          <div style={{width: "16vw"}}>
                                                              <AdminNavbar />
                                                          </div>
                                                          <div style={{width: "84vw"}}>
                                                              <Routes>
                                                                  <Route path="*" element={(<Billing/>)}/>
                                                              </Routes>
                                                          </div>
                                                      </div>
                                                  )}
                                                  {!(subscriptionPaymentFailed || subscriptionInactive) && (
                                                      <div className={"flex h-full"}>
                                                          <div style={{width: "16vw"}}>
                                                              <AdminNavbar />
                                                          </div>
                                                          <div style={{width: "84vw"}}>
                                                              <Routes>
                                                                  <Route path="*" element={(<Dashboard/>)}/>
                                                                  <Route path="/products" element={(<Products/>)}/>
                                                                  <Route path="/products/view/:id" element={(<ViewProduct/>)}/>
                                                                  <Route path="/products/configure/:id" element={(<ConfigureProduct />)}/>
                                                                  <Route path="/expenses" element={(<Expenses/>)}/>
                                                                  <Route path="/moneyback/returns" element={(<ReimbursementReports/>)}/>
                                                                  <Route path="/reports" element={(<Reports/>)}/>
                                                                  <Route path="/reports/create-edit" element={(<CreateEditReport/>)}/>
                                                                  <Route path="/settings" element={(<Settings/>)}/>
                                                                  <Route path="/notifications" element={(<AllNotifications/>)}/>
                                                                  <Route path="/billing" element={(<Billing/>)}/>
                                                                  <Route path="/help" element={(<Help/>)}/>
                                                                  <Route path="/shop-accounts" element={(<ShopAccounts/>)}/>
                                                                  <Route path="/oauth/callback" element={(<LoginWithTikTokCallback/>)}/>
                                                              </Routes>
                                                          </div>
                                                      </div>
                                                  )}
                                              </>
                                          )}
                                      </>
                                  )}
                              </>
                          )} */}
                      </AppContext.Provider>
                  </QueryClientProvider>
              </MantineProvider>
          </NextUIProvider>
      </BrowserRouter>
  );
}

export default App;
